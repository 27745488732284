<template>
  <div>
    <div class="d-flex">
      <router-link
        v-if="user.username !== message.user.username"
        :to="posterProfileLink"
        :class="{ disabled: linkDisabled }"
        class="mr-2"
      >
        <ProfileRing
          :image="!message.character ? '' : get(message.character, 'profile_ring.image')"
          :borderWidth="75"
          :ringTop="-8"
          :ringLeft="-8"
        />
        <img
          v-image
          class="message-poster-img clickable"
          :src="
            !message.character
              ? '/images/user.jpg'
              : resizeUpload(get(message.character, 'info.cropProfilePicture', '/empty.png'), '80x80')
          "
        />
      </router-link>

      <div class="card mb-3 msg-text bg-light" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="card-body d-flex p-3">
          <div class="w-100">
            <div class="d-flex align-items-center">
              <router-link :to="posterProfileLink" :class="{ disabled: linkDisabled }">
                <strong class="clickable text-black"
                  >{{ !message.character ? message.user.username : get(message.character, 'info.name', 'Unknown') }}
                </strong>
              </router-link>

              <small class="ml-auto">{{ formatDescDateTime(message.created) }}</small>
              <i
                v-if="user.username !== message.user.username"
                class="ti-more-alt mx-1 clickable-item-hov"
                @click="dropDownToggle($event, message)"
                style="font-size: 13px"
              />
            </div>
            <div class="mt-2 px-0">
              <span v-html="sanitizeHtml(message.text.replace(/(?:\r\n|\r|\n)/g, '<br>'))" />
            </div>
          </div>
        </div>
      </div>

      <router-link
        v-if="user.username === message.user.username"
        :to="posterProfileLink"
        :class="{ disabled: linkDisabled }"
        class="ml-2 img-link"
      >
        <ProfileRing
          :image="!message.character ? '' : get(message.character, 'profile_ring.image')"
          :borderWidth="75"
          :ringTop="-8"
          :ringLeft="-8"
        />
        <img
          v-image
          class="message-poster-img clickable"
          :src="
            !message.character
              ? '/images/user.jpg'
              : resizeUpload(get(message.character, 'info.cropProfilePicture', '/empty.png'), '80x80')
          "
        />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import MessagePopover from '@/shared/pages/social-spaces-chatrooms/popovers/MessagePopover.vue';
import { formatDescDateTime } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import { sanitizeHtml } from '@/shared/utils/html';
import { resizeUpload } from '@/shared/utils/upload';
import ProfileRing from '@/shared/components/ProfileRing.vue';

@Options({
  name: 'ChatMsgItem',
  components: { ProfileRing },
})
export default class ChatMsgItem extends Vue {
  @Prop() message!: any;
  resizeUpload = resizeUpload;
  sanitizeHtml = sanitizeHtml;
  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  hover = false;

  replyToMentionName: string | null = null;

  formatDescDateTime = formatDescDateTime;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;

  public get posterProfileLink() {
    return !this.message.character
      ? { name: 'profile', params: { username: this.message.user.username } }
      : {
          name: 'character-profile-new',
          params: { slug: this.message.character.slug },
        };
  }
  public dropDownToggle(ev: CustomEvent, msg: any) {
    popovers.open(ev, MessagePopover, { message: msg, isRoleChat: true });
  }
  public get linkDisabled() {
    if (this.message.character?.slug === '-') return true;
    return false;
  }

  created() {
    this.message.text = this.message.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
</script>

<style lang="sass" scoped>
.msg-text
  width: calc(100% - 70px)
.message-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover
.disabled
  opacity: 0.75
  pointer-events: none
</style>
