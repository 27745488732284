<template>
  <div>
    <div class="d-flex" :class="!isParent ? 'w-75 ml-auto' : ''">
      <ProfileRing
        :image="
          get(lastCommentAs, 'type') === commentedAsTypes.USER ? '' : get(lastCommentAs, 'item.profile_ring.image')
        "
        :borderWidth="75"
        :ringTop="-9"
        :ringLeft="-7"
        class="position-absolute"
      />
      <img
        v-image
        class="comment-poster-img"
        :src="
          get(lastCommentAs, 'type') === commentedAsTypes.USER
            ? '/images/user.jpg'
            : resizeUpload(get(lastCommentAs, 'item.info.cropProfilePicture', '/empty.png'), '80x80')
        "
      />
      <div class="w-100 ml-2">
        <form @submit.prevent>
          <ion-textarea
            id="msgText"
            v-model="msgText"
            rows="3"
            class="c-textarea m-0"
            :placeholder="`Type message here...`"
            :disabled="isPosting || !isAuthenticated"
            required
          />

          <div class="mt-2 d-flex justify-content-between">
            <div v-if="isAuthenticated" class="text-black mt-1">
              Replying as
              <span :class="{ 'text-primary': !isReplyAsUser }">{{
                isReplyAsUser ? 'yourself' : lastCommentAsName
              }}</span
              >.
              <a href="#" @click.prevent="openCommentAsList">
                <b
                  ><i>{{ switchPostAsText }}</i></b
                ></a
              >
            </div>
            <i v-else class="text-gray">Log in to send messages</i>

            <ion-button
              class="publish-btn"
              :disabled="isPosting || !isAuthenticated || !msgText"
              :color="postSuccess ? 'success' : 'primary'"
              @click.prevent="onPost"
            >
              <i v-if="postSuccess" class="ti-check-box" />
              <ChLoading size="sm" v-else-if="isPosting" class="spinner" />
              <span v-else>Send</span>
            </ion-button>
          </div>
        </form>
      </div>
    </div>

    <comment-as-select-modal
      :is-open="openCommentAsModal"
      modal-title="Message As"
      yourself-text="Message as yourself/narrator"
      :include-unlisted="true"
      @dismiss-modal="closeCommentAsModal"
      @item-selected="selectCommentAs"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { resizeUpload } from '@/shared/utils/upload';
import { toast } from '@/shared/native';
import CommentAsSelectModal from '@/shared/modals/CommentAsSelectModal.vue';
import constants from '@/shared/statics/constants';
import { sendRolechatMessage } from '@/shared/actions/rolechats';
import { authStore } from '@/shared/pinia-store/auth';
import ProfileRing from '@/shared/components/ProfileRing.vue';

@Options({
  name: 'PostChatMessage',
  components: { CommentAsSelectModal, ProfileRing },
})
export default class PostChatMessage extends Vue {
  @Prop({ default: true }) isParent!: boolean;
  @Prop({ required: false }) parentCommentId!: string;

  resizeUpload = resizeUpload;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;

  public msgText = '';
  public isPosting = false;
  public postSuccess = false;

  public openCommentAsModal = false;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get lastCommentAs() {
    const { lastCommentAs } = authStore();
    return lastCommentAs.value;
  }

  public get lastCommentAsName() {
    const { lastCommentAsName } = authStore();
    return lastCommentAsName.value;
  }

  public get isReplyAsUser() {
    return !this.lastCommentAs || this.lastCommentAs.type === constants.commentedAsTypes.USER;
  }

  public get switchPostAsText() {
    return this.isReplyAsUser ? 'Reply as your character instead!' : `Switch who you're replying as.`;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public openCommentAsList() {
    this.openCommentAsModal = true;
  }

  public closeCommentAsModal() {
    this.openCommentAsModal = false;
  }

  public selectCommentAs(data: { id: string; type: string; item: {} }) {
    const { updateLastCommentAs } = authStore();
    updateLastCommentAs({
      lastCommentAs: data,
      lastCommentAsName:
        data.type === constants.commentedAsTypes.USER
          ? get(data.item, 'username', 'Unknown')
          : get(data.item, 'info.name', 'Unknown'),
    });
  }

  public async onPost() {
    const router = useRouter();
    this.isPosting = true;
    try {
      const data = {
        chat_room: router.currentRoute.value.params.id,
        text: this.msgText,
        ...(this.lastCommentAs!.type === 'character' && {
          character: this.lastCommentAs!.id,
        }),
      };

      await sendRolechatMessage(String(router.currentRoute.value.params.id), data);
      this.postSuccess = true;
      setTimeout(() => (this.postSuccess = false), 1000);
    } catch (_e) {
      toast.show('Cannot send message. Please try again.', 'nonative', 'danger', undefined, 'top');
    } finally {
      this.msgText = '';
      this.isPosting = false;
      this.$emit('posted');
    }
  }

  public async mounted() {
    if (!this.lastCommentAsName) {
      const { updateLastCommentAs } = authStore();
      updateLastCommentAs({
        lastCommentAs: {
          id: this.user.id,
          type: constants.commentedAsTypes.USER,
          item: cloneDeep(this.user),
        },
        lastCommentAsName: this.user.username,
      });
    }
  }
}
</script>

<style lang="sass" scoped>
.comment-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover

.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px
</style>
