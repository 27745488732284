<template>
  <div>
    <div class="d-flex mx-auto" :class="isMobSmallScreen ? 'w-100' : 'w-75'">
      <div class="w-100 d-flex p-3">
        <div class="w-100">
          <div class="d-flex">
            <router-link :to="posterProfileLink">
              <strong class="clickable text-black"
                >Narrator (
                <span
                  :class="{
                    'text-color-username': usernameColor(message.user) && !usernameColor(message.user).startsWith('#'),
                    'bold-username': usernameColor(message.user),
                  }"
                  :style="
                    !usernameColor(message.user)
                      ? ''
                      : usernameColor(message.user).startsWith('#')
                      ? `color: ${usernameColor(message.user)} !important`
                      : `background-image: ${usernameColor(message.user)} !important`
                  "
                >
                  {{ message.user.username }}
                </span>
                )
              </strong>
            </router-link>
            <small class="ml-auto">{{ formatDescDateTime(message.created) }}</small>
            <i
              v-if="user.username !== message.user.username"
              class="ti-more-alt mx-1 clickable-item-hov"
              @click="dropDownToggle($event, message)"
              style="font-size: 13px"
            />
          </div>
          <div class="mt-2 px-0">
            <span v-html="sanitizeHtml(message.text.replace(/(?:\r\n|\r|\n)/g, '<br>'))" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { formatDescDateTime } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';

import MessagePopover from '@/shared/pages/social-spaces-chatrooms/popovers/MessagePopover.vue';
import { popovers } from '@/shared/native/popovers';
import { sanitizeHtml } from '@/shared/utils/html';

@Options({
  name: 'ChatNarrMsgItem',
  components: {},
})
export default class ChatNarrMsgItem extends Vue {
  @Prop() message!: any;

  sanitizeHtml = sanitizeHtml;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public usernameColor = (user: any) => {
    return user?.customize_profile?.username_color || '';
  };

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  hover = false;

  formatDescDateTime = formatDescDateTime;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;

  public get posterProfileLink() {
    return !this.message.character
      ? { name: 'profile', params: { username: this.message.user.username } }
      : {
          name: 'character-profile-new',
          params: { slug: this.message.character.slug },
        };
  }

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public dropDownToggle(ev: CustomEvent, msg: any) {
    popovers.open(ev, MessagePopover, { message: msg, isRoleChat: true });
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public goToPosterProfile() {
    const router = useRouter();
    router.push(this.posterProfileLink);
  }

  created() {
    this.message.text = this.message.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
</script>

<style lang="sass" scoped>
.message-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover
</style>
